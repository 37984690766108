import React from 'react';
import Search from './Search';

function App() {
  return (
    <>
      <div style={{ maxWidth: 400, margin: '30px auto 0', textAlign: 'center', padding: 10 }}>
        <a href="/" title="GoodSoft Umkreissuche">
          <img src="/img/logo.png" style={{ maxWidth: 300 }} alt="GoodSoft" />
        </a>
      </div>
      <Search/>
    </>
  );
}

export default App;
