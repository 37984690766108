import React, { MouseEvent, FocusEvent, ChangeEvent, Dispatch, SetStateAction, useMemo, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import { Card, CardActionArea, CardContent, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfiniteScroll from 'react-infinite-scroll-component';

const url = 'https://cities.goodsoft.de'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: 400,
      margin: '20px auto'
    },
    input: {
      // marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    container: {
      padding: 10
    }
  }),
);

type City = {
  _id: string,
  name: string,
  postalCode: string,
  lat: number,
  lon: number,
  state: string,
  districts: string[]
}

type Nearby = {
  _id: string,
  plz: string,
  name: string,
  distance: number
}

type NearbyState = {
  name: string,
  postalCode: string,
  districts: string[],
  state: string,
  nearby: Nearby[]
}

async function fetchSearchApi(search:string, setCities: Dispatch<SetStateAction<City[]>>, searchPage: number, setSearchPage: Dispatch<SetStateAction<number>>, setHasMoreSearch: Dispatch<SetStateAction<boolean>>){
  await fetch(url + '/api/cities/search/'+search+'/'+searchPage)
    .then(res => {
      return res.json()
    })
    .then(
      (result) => {
        if(result.length === 0){
          setSearchPage(2)
          setHasMoreSearch(false)
          return
        } else {
          setCities((cities) => ([...cities, ...result]));
          setSearchPage(searchPage + 1)
          setHasMoreSearch(true)
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setSearchPage(2)
        setHasMoreSearch(false)
      }
    );
}

async function fetchSearchApiFirst(search:string, setCities: Dispatch<SetStateAction<City[]>>, setHasMoreSearch: Dispatch<SetStateAction<boolean>>, setIsLoading: Dispatch<SetStateAction<boolean>>){
  await fetch(url + '/api/cities/search/'+search+'/1')
    .then(res => {
      return res.json()
    })
    .then(
      (result) => {
        setIsLoading(false);
        setCities(result);
        setHasMoreSearch(result.length > 0)
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setIsLoading(false);
        setCities([]);
        setHasMoreSearch(false)
      }
    );
}

async function fetchNearbyApi(id:string, setNearby: Dispatch<SetStateAction<NearbyState|null>>, scrollStep: number, setScrollStep: Dispatch<SetStateAction<number>>, setHasMore: Dispatch<SetStateAction<boolean>>){
  const distanceStep = 10

  const min = distanceStep * scrollStep
  const max = min + distanceStep

  setHasMore(max < 50)
  setScrollStep(scrollStep + 1)

  await fetch(url + '/api/cities/nearby/'+ id + '/' + min + '/' + max)
    .then(res => {
      return res.json()
    })
    .then(
      (result) => {
        setNearby((nearby) => {
          if(nearby !== null){
            return {
              ...result,
              nearby: [...nearby.nearby, ...result.nearby]
            }
          }
          return result
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setNearby(null);
      }
    );
}

async function fetchNearbyApiFirst(id:string, setNearby: Dispatch<SetStateAction<NearbyState|null>>, setHasMoreNearby: Dispatch<SetStateAction<boolean>>){
  await fetch(url + '/api/cities/nearby/'+ id + '/0/10')
    .then(res => {
      return res.json()
    })
    .then(
      (result) => {
        setNearby(result);
        setHasMoreNearby(result !== null)
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setNearby(null);
        setHasMoreNearby(false)
      }
    );
}

export default function Search() {
  const classes = useStyles();

  const [search, setSearch] = useState('');

  const [searchPage, setSearchPage] = useState(2);

  const [hasMoreSearch, setHasMoreSearch] = useState(true)

  const [cities, setCities] = useState<City[]>([])

  const [nearby, setNearby] = useState<NearbyState|null>(null)

  const [nearbyId, setNearbyId] = useState<string>('')

  const [hasMoreNearby, setHasMoreNearby] = useState(true)

  const [scrollStep, setScrollStep] = useState(1)

  const [timeoutId, setTimeoutId] = useState<number|undefined>(undefined)

  const [isLoading, setIsLoading] = useState(false)

  const handleSearch = (value: string) => {
    setSearch(value)
    setNearby(null)
    setNearbyId('')
    setScrollStep(1)
  };

  const handleNearbyId = (_id:string) => {
    setNearbyId(_id)
  };

  useEffect(() => {
    if(typeof timeoutId !== 'undefined'){
      window.clearTimeout(timeoutId)
      setTimeoutId(undefined)
    }
    if(search.length >= 2){
      setIsLoading(true)
      const timeoutID = window.setTimeout(() => {
        setTimeoutId(undefined)
        fetchSearchApiFirst(search, setCities, setHasMoreSearch, setIsLoading)
        setSearchPage(2)
      }, 500)
      setTimeoutId(timeoutID)
    } else {
      setCities([])
      setNearby(null)
      setIsLoading(false)
    }
  }, [search])


  useMemo(() => {
    if(nearbyId !== ''){
      fetchNearbyApiFirst(nearbyId, setNearby, setHasMoreNearby)
      setScrollStep(1)
    }
  }, [nearbyId])

  let resultBoxes = [
    <Card key={0} className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Kein Ergebnis
        </Typography>
        <Typography color="textSecondary" component="p">
          Geben Sie eine Stadt oder Postleitzahl an.
        </Typography>
      </CardContent>
    </Card>
  ]

  if(cities.length > 0){
    resultBoxes = [<InfiniteScroll
        key="infiniteScrollSearch"
        dataLength={cities.length}
        next={() => {fetchSearchApi(search, setCities, searchPage, setSearchPage, setHasMoreSearch)}}
        hasMore={hasMoreSearch}
        loader={<div className={classes.root}><CircularProgress style={{margin: 'auto'}} /></div>}
      >
        {
          cities.map((city) => (
            <Card 
              key={city._id} 
              id={city._id} 
              className={classes.root} 
              onClick={() => { 
                  // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                  window.scrollTo(0,0);
                  handleNearbyId(city._id);
                }
              }>
              <CardActionArea>
                <CardContent>
                  <Typography color="textSecondary">
                    {city.state}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {city.name}
                  </Typography>
                  <Typography color="textSecondary">
                    {city.postalCode}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {city.districts.join(', ')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        }
      </InfiniteScroll>]
  }

  if(nearby !== null){
    resultBoxes = [
      <InfiniteScroll
        key="infiniteScroll"
        dataLength={nearby.nearby.length}
        next={() => {fetchNearbyApi(nearbyId, setNearby, scrollStep, setScrollStep, setHasMoreNearby)}}
        hasMore={hasMoreNearby}
        loader={<div className={classes.root}><CircularProgress style={{margin: 'auto'}} /></div>}
      >
        { 
        (nearby.nearby.length > 0) ?
          nearby.nearby.map((nearbyElement, key) => (
            <Card onClick={() => {
                // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                window.scrollTo(0,0);
                handleNearbyId(nearbyElement._id); 
              }} key={key} className={classes.root}>
                <CardActionArea>
                  <CardContent>
                    <Typography color="textSecondary">
                      {nearbyElement.distance.toFixed(2).replace('.', ',')} km
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {nearbyElement.name}
                    </Typography>
                    <Typography color="textSecondary">
                      {nearbyElement.plz}
                    </Typography>
                  </CardContent>
                </CardActionArea>
            </Card>
          ))
          : []
        }
      </InfiniteScroll>
    ]
    if(nearby.name !== ''){
      resultBoxes = [
        <Card key="titleCard" className={classes.root}>
          <CardContent>
            <Typography color="textSecondary">
              {nearby.state}
            </Typography>
            <Typography variant="h5" component="h2">
              {nearby.name} ({nearby.postalCode})
            </Typography>
            <Typography color="textSecondary">
              {nearby.districts.join(', ')}
            </Typography>
          </CardContent>
        </Card>,
        ...resultBoxes
      ]
    }
  }

  if(isLoading === true){
    resultBoxes = [
        <div key="loadingSpinner" className={classes.root}>
          <CircularProgress style={{margin: 'auto'}} />
        </div>
      ]
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Umkreissuche"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
              onFocus={(e: FocusEvent<HTMLInputElement>) => handleSearch(e.target.value)}
              value={search}
            />
            <IconButton onClick={(e: MouseEvent<HTMLElement>) => handleSearch(search)} className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid xs={12} item>
          {resultBoxes}
        </Grid>
      </Grid>
    </>
  );
}